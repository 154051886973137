<template>
  <div class="avatar-builder">
    <div class="fill-height d-flex align-start justify-center flex-wrap">
      <div class="render col-12 col-md-5 col-lg-6 d-flex align-center flex-column justify-start">

        <v-btn @click="showSwatches = !showSwatches" text color="secondary" class="px-0">
          <v-avatar size="24" class="mr-2" :style="`background-color: ${swatch}`"></v-avatar> Set background color</v-btn>

          <v-color-picker v-if="showSwatches" hide-canvas class="ma-2" v-model="swatch" :value="swatch" hide-inputs hide-mode-switch show-swatches @update:color="showSwatches = false"></v-color-picker>
        <v-spacer class="col-1"></v-spacer>

        <div class="pa-2 white elevation-4" style="border-radius:6px">
          <v-avatar size="180" :style="`background-color:${swatch}`">
            <v-img :src="image" ref="image">
              <template v-slot:placeholder>
                image loading...
              </template>
            </v-img>
          </v-avatar>
        </div>


        <v-spacer class="col-4"></v-spacer>

        <div class="d-flex align-center justify-stretch mb-4">
          <v-btn text @click="cancelAvatar">Cancel</v-btn>
          <v-btn color="primary" @click="saveAvatar">Done Editing</v-btn>
        </div>
        <!-- <v-radio-group v-model="avatarStyle" row @change="buildImage">
          <v-radio label="Circle" value="Circle"></v-radio>
          <v-radio label="Transparent" value="Transparent"></v-radio>
        </v-radio-group> -->
      </div>
      <div class="options d-flex flex-column align-center col-12 col-md-7 col-lg-6" v-if="!$isEmpty(form)">

        <v-form v-model="avatarForm" ref="avatar">

          <div class="fill-height d-flex align-center justify-center flex-wrap pa-0">
            <div class="col-12 col-md-6 pa-0 pr-2">
              <span class="label">💇‍♀️ Hair</span>
              <v-select
              v-model="form.topType"
              dense
              solo
              class="form-control"
              :items="options.topType"
              item-text="text"
              item-value="value"
              return-object
              :value="options.topType[0]"
              @change="buildImage"
              ></v-select>

            </div>
            <div class="col-12 col-md-6 pa-0 pl-2">
              <template v-if="!$isEmpty(form) && !Object.hasOwnProperty.call(form.topType, 'hairColor') && !Object.hasOwnProperty.call(form.topType,'hatColor')">
                <span class="label">🎨 Hair Color</span>
                <v-select
                v-model="form.hairColor"
                :disabled="!$isEmpty(form) && Object.hasOwnProperty.call(form.topType,'hairColor')"
                dense
                solo
                class="form-control"
                :items="options.hairColor"
                item-text="text"
                item-value="value"
                return-object
                :value="options.hairColor[0]"
                @change="buildImage"
                ></v-select>
              </template>
              <template v-if="!$isEmpty(form) && Object.hasOwnProperty.call(form.topType,'hatColor')">
                <span class="label">🎨 Hat Color</span>
                <v-select
                v-model="form.hatColor"
                dense
                solo
                class="form-control"
                :items="options.hatColor"
                item-text="text"
                item-value="value"
                return-object
                :value="options.hatColor[0]"
                @change="buildImage"
                ></v-select>
              </template>
            </div>

          </div>

          <div class="fill-height d-flex align-center justify-center flex-wrap pa-0">
            <div class="col-12 col-md-6 pa-0 pr-2">
              <span class="label">👁 Eyes</span>
              <v-select
              v-model="form.eyeType"
              dense
              solo
              class="form-control"
              :items="options.eyeType"
              item-text="text"
              item-value="value"
              return-object
              :value="options.eyeType[0]"
              @change="buildImage"
              ></v-select>

            </div>
            <div class="col-12 col-md-6 pa-0 pl-2">
              <span class="label">✏️ Eyebrow</span>
              <v-select
              v-model="form.eyebrowType"
              dense
              solo
              class="form-control"
              :items="options.eyebrowType"
              item-text="text"
              item-value="value"
              return-object
              :value="options.eyebrowType[0]"
              @change="buildImage"
              ></v-select>
            </div>
          </div>

          <div class="fill-height d-flex align-center justify-center flex-wrap pa-0">
            <div class="col-12 col-md-6 pa-0 pr-2">
              <span class="label">👓 Glasses</span>
              <v-select
              v-model="form.accessoriesType"
              :disabled="!$isEmpty(form) && Object.hasOwnProperty.call(form.topType,'accessoriesType')"
              dense
              solo
              class="form-control"
              :items="options.accessoriesType"
              item-text="text"
              item-value="value"
              return-object
              :value="options.accessoriesType[0]"
              @change="buildImage"
              ></v-select>
            </div>
            <v-spacer></v-spacer>
          </div>



        <div class="fill-height d-flex align-center justify-center flex-wrap pa-0">
          <div class="col-12 col-md-6 pa-0 pr-2">
            <span class="label">👄 Mouth</span>
            <v-select
            v-model="form.mouthType"
            dense
            solo
            class="form-control"
            :items="options.mouthType"
            item-text="text"
            item-value="value"
            return-object
            :value="options.mouthType[0]"
            @change="buildImage"
            ></v-select>

          </div>
          <div class="col-12 col-md-6 pa-0 pl-2">
            <span class="label">🎨 Skin</span>
            <v-select
            v-model="form.skinColor"
            dense
            solo
            class="form-control"
            :items="options.skinColor"
            item-text="text"
            item-value="value"
            return-object
            :value="options.skinColor[0]"
            @change="buildImage"
            ></v-select>
          </div>
        </div>

        <div class="fill-height d-flex align-center justify-center flex-wrap pa-0">
          <div class="col-12 col-md-6 pa-0 pr-2">
            <span class="label">✂️ Facial Hair</span>
            <v-select
            v-model="form.facialHairType"
            dense
            solo
            class="form-control"
            :items="options.facialHairType"
            item-text="text"
            item-value="value"
            return-object
            :value="options.facialHairType[0]"
            @change="buildImage"
            ></v-select>

          </div>
          <div class="col-12 col-md-6 pa-0 pl-2">
            <span class="label">✂️🎨 Facial Hair Color</span>
            <v-select
            v-model="form.facialHairColor"
            dense
            solo
            class="form-control"
            :items="options.facialHairColor"
            item-text="text"
            item-value="value"
            return-object
            :value="options.facialHairColor[0]"
            @change="buildImage"
            ></v-select>
          </div>
        </div>

        <div class="fill-height d-flex align-center justify-center flex-wrap pa-0">
          <div class="col-12 col-md-6 pa-0 pr-2">
            <span class="label">👔 Clothes</span>
            <v-select
            v-model="form.clotheType"
            dense
            solo
            class="form-control"
            :items="options.clotheType"
            item-text="text"
            item-value="value"
            return-object
            :value="options.clotheType[0]"
            @change="buildImage"
            ></v-select>

          </div>
          <div class="col-12 col-md-6 pa-0 pl-2">
            <span class="label">👔🎨 Clothes Color</span>
            <v-select
            v-model="form.clotheColor"
            :disabled="!Object.hasOwnProperty.call(form.clotheType,'clotheColor')"
            dense
            solo
            class="form-control"
            :items="options.hatColor"
            item-text="text"
            item-value="value"
            return-object
            :value="options.hatColor[0]"
            @change="buildImage"
            ></v-select>

          </div>
        </div>

        <div class="fill-height d-flex align-center justify-center flex-wrap pa-0" v-if="Object.hasOwnProperty.call(form,'clotheType')">

          <div class="col-12 col-md-6 pa-0 pr-2">
            <span class="label">🎨 Graphic</span>
            <v-select
            v-model="form.graphicType"
            :disabled="!$isEmpty(form) && !Object.hasOwnProperty.call(form.clotheType,'graphicType')"
            dense
            solo
            class="form-control"
            :items="options.graphicType"
            item-text="text"
            item-value="value"
            return-object
            :value="options.graphicType[0]"
            @change="buildImage"
            ></v-select>
          </div>
          <v-spacer></v-spacer>
        </div>



        </v-form>
      </div>
    </div>
    <div class="text-xs-center text-sm-center">
      <i>powered by:</i> <a href="https://avataaars.com" target="_blank">avataaars.com</a>
    </div>






  </div>
</template>

<script>

export default {
  name: "avatar-builder",
  props: ['user'],
  data(){
    return{
      avatarForm: true,
      form: {},
      // image: 'https://avataaars.io/?accessoriesType=Prescription01&avatarStyle=Circle&clotheType=Hoodie&eyeType=EyeRoll&eyebrowType=UnibrowNatural&facialHairType=BeardLight&hairColor=Black&mouthType=Eating&skinColor=Yellow&topType=LongHairShavedSides',
      image: "null",
      parsed: {},
      swatch: "#ffffff",
      showSwatches: false,
      options:{
          avatarStyle:[{value:"Transparent", text:"Transparent"}],
          topType:[
          {value:"NoHair", text:"No Hair", hairColor: true},
          {value:"Eyepatch", text:"Eyepatch", accessoriesType: true, hairColor: true},
          {value:"Hat", text:"Hat", hairColor: true},
          {value:"Hijab", text:"Hijab", hairColor: true, hatColor: true},
          {value:"Turban", text:"Turban", hairColor: true, hatColor: true},
          {value:"WinterHat1", text:"Winter Hat 1", hairColor: true, hatColor:true},
          {value:"WinterHat2", text:"Winter Hat 2", hairColor: true, hatColor: true},
          {value:"WinterHat3", text:"Winter Hat 3", hairColor: true, hatColor: true},
          {value:"WinterHat4", text:"Winter Hat 4", hairColor: true, hatColor: true},
          {value:"LongHairBigHair", text:"Long Hair - Big Hair"},
          {value:"LongHairBob", text:"Long Hair Bob"},
          {value:"LongHairBun", text:"Long Hair Bun"},
          {value:"LongHairCurly", text:"Long Hair - Curly"},
          {value:"LongHairCurvy", text:"Long Hair - Curvy"},
          {value:"LongHairDreads", text:"Long Hair - Dreads"},
          {value:"LongHairFrida", text:"Long Hair - Frida"},
          {value:"LongHairFro", text:"Long Hair - Fro"},
          {value:"LongHairFroBand", text:"Long Hair - Fro Band"},
          {value:"LongHairNotTooLong", text:"Long Hair - Not Too Long"},
          {value:"LongHairShavedSides", text:"Long Hair - ShavedSides"},
          {value:"LongHairMiaWallace", text:"Long Hair - MiaWallace"},
          {value:"LongHairStraight", text:"Long Hair - Straight"},
          {value:"LongHairStraight2", text:"Long Hair - Straight2"},
          {value:"LongHairStraightStrand", text:"Long Hair - Straight Strand"},
          {value:"ShortHairDreads01", text:"Short Hair - Dreads 01"},
          {value:"ShortHairDreads02", text:"Short Hair - Dreads 02"},
          {value:"ShortHairFrizzle", text:"Short Hair - Frizzle"},
          {value:"ShortHairShaggyMullet", text:"Short Hair - Shaggy Mullet"},
          {value:"ShortHairShortCurly", text:"Short Hair - Short Curly"},
          {value:"ShortHairShortFlat", text:"Short Hair - Short Flat"},
          {value:"ShortHairShortRound", text:"Short Hair - Short Round"},
          {value:"ShortHairShortWaved", text:"Short Hair - Short Waved"},
          {value:"ShortHairSides", text:"Short Hair - Sides"},
          {value:"ShortHairTheCaesar", text:"Short Hair - The Caesar"},
          {value:"ShortHairTheCaesarSidePart", text:"Short Hair - The Caesar Side Part"}
        ],
        accessoriesType:[
          {value:"Blank", text:"None"},
          {value:'Kurt', text:'Kurt'},
          {value:'Prescription01', text:'Prescription01'},
          {value:'Prescription02', text:'Prescription02'},
          {value:'Round', text:'Round'},
          {value:'Sunglasses', text:'Sunglasses'},
          {value:'Wayfarers', text:'Wayfarers'}
        ],
        hairColor:[
          {value:'Auburn', text:'Auburn'},
          {value:'Black', text:'Black'},
          {value:'Blonde', text:'Blonde'},
          {value:'BlondeGolden', text:'Blonde Golden'},
          {value:'Brown', text:'Brown'},
          {value:'BrownDark', text:'Brown Dark'},
          {value:'PastelPink', text:'Pastel Pink'},
          {value:'Platinum', text:'Platinum'},
          {value:'Red', text:'Red'},
          {value:'SilverGray', text:'Silver/Gray'},
        ],
        eyeType:[
          {value:"Close", text:"Close"},
          {value:"Cry", text:"Cry"},
          {value:"Default", text:"Default"},
          {value:"Dizzy", text:"Dizzy"},
          {value:"EyeRoll", text:"EyeRoll"},
          {value:"Happy", text:"Happy"},
          {value:"Hearts", text:"Hearts"},
          {value:"Side", text:"Side"},
          {value:"Squint", text:"Squint"},
          {value:"Surprised", text:"Surprised"},
          {value:"Wink", text:"Wink"},
          {value:"WinkWacky", text:"WinkWacky"},
        ],
        clotheType:[
          {value:"BlazerShirt", text:"Blazer Shirt"},
          {value:"BlazerSweater", text:"Blazer Sweater"},
          {value:"CollarSweater", text:"Collar Sweater", clotheColor: true},
          {value:"GraphicShirt", text:"Graphic Shirt", clotheColor: true, graphicType:true},
          {value:"Hoodie", text:"Hoodie", clotheColor: true},
          {value:"Overall", text:"Overall", clotheColor: true},
          {value:"ShirtCrewNeck", text:"Shirt Crew Neck", clotheColor: true},
          {value:"ShirtScoopNeck", text:"Shirt Scoop Neck", clotheColor: true},
          {value:"ShirtVNeck", text:"Shirt V-Neck", clotheColor: true},
        ],
        clotheColor:[
          {value:"Black", text:"Black"},
          {value:"Blue01", text:"Blue 01"},
          {value:"Blue02", text:"Blue 02"},
          {value:"Blue03", text:"Blue 03"},
          {value:"Gray01", text:"Gray 01"},
          {value:"Gray02", text:"Gray 02"},
          {value:"Heather", text:"Heather"},
          {value:"PastelBlue", text:"Pastel Blue"},
          {value:"PastelGreen", text:"Pastel Green"},
          {value:"PastelOrange", text:"Pastel Orange"},
          {value:"PastelRed", text:"Pastel Red"},
          {value:"PastelYellow", text:"Pastel Yellow"},
          {value:"Pink", text:"Pink"},
          {value:"Red", text:"Red"},
          {value:"White", text:"White"},
        ],
        hatColor:[
          {value:"Black", text:"Black"},
          {value:"Blue01", text:"Blue 01"},
          {value:"Blue02", text:"Blue 02"},
          {value:"Blue03", text:"Blue 03"},
          {value:"Gray01", text:"Gray 01"},
          {value:"Gray02", text:"Gray 02"},
          {value:"Heather", text:"Heather"},
          {value:"PastelBlue", text:"Pastel Blue"},
          {value:"PastelGreen", text:"Pastel Green"},
          {value:"PastelOrange", text:"Pastel Orange"},
          {value:"PastelRed", text:"Pastel Red"},
          {value:"PastelYellow", text:"Pastel Yellow"},
          {value:"Pink", text:"Pink"},
          {value:"Red", text:"Red"},
          {value:"White", text:"White"},
        ],
        eyebrowType:[
          {value:"Angry", text:"Angry"},
          {value:"AngryNatural", text:"Angry Natural"},
          {value:"Default", text:"Default"},
          {value:"DefaultNatural", text:"Default Natural"},
          {value:"FlatNatural", text:"Flat Natural"},
          {value:"RaisedExcited", text:"Raised Excited"},
          {value:"RaisedExcitedNatural", text:"Raised Excited Natural"},
          {value:"SadConcerned", text:"Sad Concerned"},
          {value:"SadConcernedNatural", text:"Sad Concerned Natural"},
          {value:"UnibrowNatural", text:"Unibrow Natural"},
          {value:"UpDown", text:"Up Down"},
          {value:"UpDownNatural", text:"Up Down Natural"},
        ],
        graphicType:[
          {value:"Bat", text:"Bat"},
          {value:"Cumbia", text:"Cumbia"},
          {value:"Deer", text:"Deer"},
          {value:"Diamond", text:"Diamond"},
          {value:"Hola", text:"Hola"},
          {value:"Pizza", text:"Pizza"},
          {value:"Resist", text:"Resist"},
          {value:"Selena", text:"Selena"},
          {value:"Bear", text:"Bear"},
          {value:"SkullOutline", text:"Skull Outline"},
          {value:"Skull", text:"Skull"},
        ],
        facialHairType:[
          {value:"Blank", text:"Blank"},
          {value:"BeardMedium", text:"BeardMedium"},
          {value:"BeardLight", text:"BeardLight"},
          {value:"BeardMagestic", text:"BeardMagestic"},
          {value:"MoustacheFancy", text:"MoustacheFancy"},
          {value:"MoustacheMagnum", text:"MoustacheMagnum"},
        ],
        facialHairColor:[
          {value:'Auburn', text:'Auburn'},
          {value:'Black', text:'Black'},
          {value:'Blonde', text:'Blonde'},
          {value:'BlondeGolden', text:'Blonde Golden'},
          {value:'Brown', text:'Brown'},
          {value:'BrownDark', text:'Brown Dark'},
          {value:'Platinum', text:'Platinum'},
          {value:'Red', text:'Red'},
        ],
        mouthType:[
          {value:"Concerned", text:"Concerned"},
          {value:"Default", text:"Default"},
          {value:"Disbelief", text:"Disbelief"},
          {value:"Eating", text:"Eating"},
          {value:"Grimace", text:"Grimace"},
          {value:"Sad", text:"Sad"},
          {value:"ScreamOpen", text:"Scream Open"},
          {value:"Serious", text:"Serious"},
          {value:"Smile", text:"Smile"},
          {value:"Tongue", text:"Tongue"},
          {value:"Twinkle", text:"Twinkle"},
          {value:"Vomit", text:"Vomit"},
        ],
        skinColor:[
          {value:"Tanned", text:"Tanned"},
          {value:"Yellow", text:"Yellow"},
          {value:"Pale", text:"Pale"},
          {value:"Light", text:"Light"},
          {value:"Brown", text:"Brown"},
          {value:"DarkBrown", text:"Dark Brown"},
          {value:"Black", text:"Black"},
        ],

      },
    }
  },
  methods: {
    updateImage(){
      this.buildImage();
    },
    buildImage(){
      let url = `https://avataaars.io/?`;
      for (let [key, value] of Object.entries(this.form)) {
          // console.log(key, value);
          url = url + `&${key}=${value.value}`
      }
      process.env.NODE_ENV === "development" ? console.log( 'url', url ) : null;
      this.image = url;
    },
    cancelAvatar(){
      this.$emit('closeAvatar', true)
    },
    saveAvatar(){
      this.$emit('saveAvatar', {avatar:this.image, bg: this.swatch})
    },
    /**
     * This function returns an object that contains every get parameter from a URL (first argument) as a property
     *
     * @param URL {String}
     */
    QueryString(URL) {
        // This function is anonymous, is executed immediately and
        // the return value is assigned to QueryString!
        var query_string = {};
        var usefulParam = URL.split("?")[1] || "";
        var query = usefulParam || "";
        var vars = query.split("&");

        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");

            // If first entry with this name
            if (typeof query_string[pair[0]] === "undefined") {
                query_string[pair[0]] = decodeURIComponent(pair[1]);
                // If second entry with this name
            } else if (typeof query_string[pair[0]] === "string") {
                var arr = [query_string[pair[0]], decodeURIComponent(pair[1])];
                query_string[pair[0]] = arr;
                // If third or later entry with this name
            } else {
                query_string[pair[0]].push(decodeURIComponent(pair[1]));
            }
        }

        return query_string;
    }

  },
  mounted(){
    let vThis = this;
    if(this.user.avatar != undefined){
      this.image = this.user.avatar;
      let query = this.QueryString(this.user.avatar);
      let queryArray = Object.entries(query);
      process.env.NODE_ENV === "development" ? console.log( queryArray ) : null;
      for( const[key,val] of queryArray){
        process.env.NODE_ENV === "development" ? console.log( key, val, typeof val) : null;
        process.env.NODE_ENV === "development" ? console.log(key !== ""  , val !== 'undefined' , val !== undefined) : null;
        if(key !== ""){
          process.env.NODE_ENV === "development" ? console.log(vThis.options[key]) : null;
          let value = vThis.options[key].find(x => x.value === val)
          vThis.form[key] = value;
        }
      }

      this.swatch = this.user.bg;

    }
    else{
      this.swatch = '#FFFFFF';
      this.form = {
        avatarStyle:{value:"Transparent", text:"Transparent"},
        topType:this.options.topType[0],
        accessoriesType:this.options.accessoriesType[0],
        hairColor:this.options.hairColor[0],
        hatColor:this.options.hatColor[0],
        facialHairType:this.options.facialHairType[0],
        facialHairColor:this.options.facialHairColor[0],
        clotheType:this.options.clotheType[0],
        clotheColor:this.options.clotheColor[0],
        graphicType:this.options.graphicType[0],
        eyeType:this.options.eyeType[0],
        eyebrowType:this.options.eyebrowType[0],
        mouthType:this.options.mouthType[0],
        skinColor:this.options.skinColor[0],
      }
      this.buildImage();
    }
  }
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .avatar-builder{
    .render{
      max-width: 300px;
      img #Avataaar\/Circle g#Color\/Palette\/Blue-01{
        fill:red
      }
    }
    .v-color-picker__controls{
      display: none;
    }
  }

</style>
